.userFeatures{
  display: flex;
  justify-content: right;
  margin-right: 40px;
    margin-top: 30px;
    margin-bottom: 1.5rem;

    a{
        font-size: 1.6rem;
        color: black;
        margin-right: 15px;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: inline-block;

        &:hover{
          transform: scale(1.2) !important;
        }
    }
}

.projects__mainTitle{
  color: black;
  text-align: center;
  font-size: 2.5rem;
}

@media screen and (min-width:480px) {
  .projects__mainTitle{
    font-size: 3rem;
    margin-bottom: 4rem
  }

  .userFeatures{
    right: 50px;

    a{
      font-size: 1.8rem;
    }
   
  }
}

@media screen and (min-width:768px) {
  .userFeatures{
    a{
      font-size: 2rem;
    }
  }
}


@media screen and (min-width:1024px) {
  .projects__mainTitle{
    font-size: 4rem;
    margin: 5rem 0;
  }
}

