.add {
    margin-top: 20px;
    display: flex;
    gap: 20px;

    .createPostContent {
      flex: 5;
      display: flex;
      flex-direction: column;
      gap: 20px;

      input {
        padding: 10px;
        border: 1px solid lightgray;
        height: 25px;


      }

      .editorContainer {
        height: 300px;
        overflow: scroll;
        border: 1px solid lightgray;

        .editor {
          height: 100%;
          border: none;
        }
      }
    }

    .menu {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .item {
        border: 1px solid lightgray;
        padding: 10px;
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
        color: #555;

        h1{
          font-size: 20px;
        }

        .file{
          text-decoration: underline;
          cursor: pointer;
        }

        .buttons{
          display: flex;
          justify-content: space-between;

          :first-child{
            cursor: pointer;
            color: teal;
            background-color: white;
            border: 1px solid teal;
            padding: 3px 5px;
          }
          :last-child{
            cursor: pointer;
            color: white;
            background-color: teal;
            border: 1px solid teal;
            padding: 3px 5px;
          }
        }

        .cat{
          display: flex;
          align-items: center;
          gap: 2px;
          color: teal;
        }
      }
    }
  }

