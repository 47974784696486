input ::placeholder {
    color: red;
    opacity: 1; /* Firefox */
  }

input{
    
    width: 300px;
    height: 40px;
    border-radius: 8px;
}

button{
    width: 300px;
    margin-top: 30px;
    border-radius: 8px;
    
}

.login__Container{
    background-color: black;
    height: 100vh;
    margin-top: -20px;
}

.form{
    display: flex;
    flex-direction: column;
    align-items:  center;

}